<template>
  <div class="dialog-container" v-if="data.paymentQRCode">
    <!-- <el-dialog
      title="支付"
      :visible.sync="show"
      @open="openDialog"
      :before-close="closeDialog"
      :close-on-click-modal="false"
      center
      width="50%"> -->
      <el-container>
        <el-tabs type="border-card" style="width:80%;margin:0 auto;">
          <el-tab-pane label="微信" class="pay-wrap">
          <h3 style="text-align: center;">{{data.venueName}}</h3>
          <h4 style="text-align: center;">一一一订单详情一一一</h4>
          <div class="content">
            <div class="item">
              <div>订单号</div>
              <div class="info">{{data.orderNo}}</div>
            </div>
            <div class="item">
              <div>下单时间</div>
              <div class="info">{{data.orderTime}}</div>
            </div>
            <div class="item">
              <div>客户姓名</div>
              <div class="info">{{data.customerName}}</div>
            </div>
            <div class="item">
              <div>收银员</div>
              <div class="info">{{data.cashier}}</div>
            </div>
          </div>
          <h4 style="text-align: center;">一一一消费明细一一一</h4>
          <p style="text-align: center;">{{data.venueName}}{{typeText}}</p>
          <div style="overflow: hidden;">
            <div class="item">
              <div>数量</div>
              <div class="info">x {{!isTicket ? number : data.ticketOutputList[0].qty}}</div>
            </div>
            <template v-if="!isTicket">
                <div class="item" v-for="(item, index) in data.spaceOutputList" :key="index">
                    <div>{{item.spaceName}}</div>
                    <div v-for="(itemS, indexS) in item.detailOutputList" :key="indexS" style="float: right;">
                        <div>{{itemS.timeSlot}}</div>
                        <div>￥{{itemS.price}}元</div>
                    </div>
                  </div>
            </template>
            <div class="item" v-else>
                <div>消费时段</div>
                <div v-for="(itemS) in data.ticketOutputList" :key="`${itemS}`" style="float: right;margin: 5px 10px 5px 10px;">
                    <div>{{itemS.startTime}}~{{itemS.endTime}}</div>
                    <div>￥{{itemS.price}}元</div>
                </div>
            </div>
          </div>
          <div class="bottom">
            <div class="item">
              <div>合计</div>
              <div class="info">{{data.totalPrice}}</div>
            </div>
            <div class="item">
                <div>待支付</div>
                <div class="info">{{data.toBePaidPrice}}</div>
              </div>
            <div class="codeImg">
                <img :src="data.paymentQRCode" alt="">
                <p>支付</p>
                <!-- <p>扫码收藏店铺</p> -->
                <!-- <p>谢谢光临，欢迎再来</p> -->
            </div>
          </div>
          </el-tab-pane>
          <!-- <el-tab-pane label="现金">
            <el-form ref="form" label-width="80px">
                <el-form-item label="实收">
                    <el-input clearable class="radius-input" v-model="form.reallyPrice" placehoider="输入收银金额">
                      <p slot="suffix">元</p>
                      <p slot="prefix">¥</p>
                    </el-input>
                    <el-button round class="sell">结算</el-button>
                  </el-form-item>
            </el-form>
          </el-tab-pane> -->
        </el-tabs>
      </el-container>
      <!-- <template slot="footer">
        <el-button @click="sureEvent">{{$t('sure')}}</el-button>
        <el-button @click="closeDialog">{{$t('cancel')}}</el-button>
      </template>
    </el-dialog> -->
  </div>
</template>
<script>
import { mapState } from 'vuex'
import mixin from '@/mixins/dialogMixin'
import apis from '@/apis'

export default {
  mixins: [mixin],
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    isTicket: {
      required: true,
      type: Boolean,
    },
    Language: {
      required: false,
      type: Number,
      default: 0,
    },
    typeText: {
      required: false,
      type: String,
      default: '预约',
    },
  },
  data() {
    return {
      depts: [],
      data: {},
      form: {},
      number: '',
    }
  },
  computed: {
    ...mapState(['DROPDOWNBOX']),
  },
  methods: {
    /**
     * @function 初始化弹窗
     */
    init() {
      // this.$refs.form.resetFields()
      console.log('我初始化了')
    },
    /**
     * @function 点击确定按钮，先执行业务，完了调关闭方法，不要写两次关闭方法
     */
    sureEvent() {
      // 这里写业务需求
      this.closeDialog()
      console.log(`我执行了点确定的业务需求`)
      // 调用关闭方法，不要再写一次
    },
    /**
     * @function 回显的时候获取数据
     * @param {Object} data 要传输给后端的数据
     */
    getDataQuery(data, type) {
      console.log("回显的数据------>", data)
      // 在这里获取回显的数据
      this.number = this.isTicket ? 999 : data.detailSaveInputList.length
      this.$http.post(this.isTicket ? apis.tickeOrder_save : apis.reservateOrder_save, data).then((payInfo) => {
        if (payInfo.data.code === 0) {
          const id = {
           id: payInfo.data.rows.id,
          }
         if (type === 1) {
           this.$emit('refesh')
           this.$message.success('保存成功')
          } else if (type === 2) {
            this.$http.post(this.isTicket ? apis.tic_ReservateOrderById : apis.getReservateOrderById, id).then((res1) => {
            console.log(res1, 'res1')
            this.data = res1.data.rows
            const wsServer = `ws://zhcg.one-info.cn/payapi/websocket/${this.data.orderNo}`
            const websocket = new WebSocket(wsServer) // 创建WebSocket对象
            console.log(websocket.readyState) // 查看websocket当前状态
            websocket.onmessage = (evt) => {
              console.log(evt, '推送数据')
              const data1 = JSON.parse(evt.data)
              if (data1.type === 'pcreservate') {
                if (data1.code === '0') {
                  this.$emit('closeDialog')
                  this.$emit('refesh')
                  this.$message.success('支付成功')
                }
              }
            }
          })
          }
        }
      })
    },
    // 获取层级
    getPermissions() {
      this.depts = [
        {
          label: '无',
          value: '0',
        },
      ]
      this.$http.get(apis.dept_list).then((res) => {
        this.buildtree(res.data.rows, this.depts, 0)
      })
    },
    buildtree(list, arr, parentId) {
      list.forEach((item) => {
        if (item.parentId === parentId) {
          const child = {
            key: item.deptId,
            value: item.deptId.toString(),
            label: item.deptName,
            children: [],
          }
          this.buildtree(list, child.children, item.deptId)
          arr.push(child)
        }
      })
    },
  },
}
</script>
<style lang="less" scoped>
  .pay-wrap{
    padding: 20px;
    border: 1px solid #DCDFE6;
    margin: 0 auto;
    width: 50%;
    border-radius: 20px;
    .content{
      h4{
        margin-top: 20px;
        margin-bottom: 10px;
      }
    }
  }
  .item{
    overflow: hidden;
    div{
      display: inline-block;
      margin: 5px 10px 5px 30px;
      text-align: left;
    }
    .info{
      float: right;
      margin-right: 40px;
    }
  }
  .bottom{
    margin-top: 30px;
    .codeImg{
      text-align: center;
    }
  }
  .sell{
    float: right;
    width:90px;
    height:30px;
    line-height: 4px;
    color: #fff;
    background: #439BFF;
    margin-top: 20px;
  }
</style>
